   iframe {
     width: 100%;
     margin: auto;
     min-width: 50%;
     max-width: 90%;
     min-height: 75%;
     color: white;
     position: absolute;
     z-index: 3;
     margin: auto;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     text-align: center;
   }

   .sk-spinner.loading.text-center {
     color: white;
     position: absolute;
     z-index: 3;
     top: 0;
     bottom: 0;
     margin: auto;
     left: 0;
     right: 0;
     text-align: center;
   }

   @media only screen and (max-width: 767px) {
     iframe {
       top: unset;
       bottom: unset;
     }
   }
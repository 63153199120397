/* backdrop*/
#backdrop.show {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  left: 0;
  flex-direction: column;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  background: initial;
  height: 100vh;
  background-position: initial;
  background-repeat: initial;
  background-size: initial;
  position: absolute;
  padding: 1.25rem;
  opacity: 1;
  z-index: 2;
  padding: 4rem 4rem 4rem 2.5rem !important;
  width: 100%;
  max-width: unset;
}

#backdrop.hide {
  transition: opacity 350ms linear;
  background: transparent;
  backdrop-filter: blur(0);
}

div.toast {
  /* visibility: hidden; */
  margin-inline: auto;
  color: #fff;
  text-align: center;
  position: fixed;
  z-index: 1001;
  font-size: 2rem;
  display: none;
  padding: 1rem;
  box-shadow: 0px 0px 13px 0px #3F39704D;
  border-radius: 1rem;
  background-color: #F8F8FB;
  color: black;
  /** Fonts */
  font-weight: 500;
}

div.toast.show {
  display: flex;
  max-width: 56.5rem;
}

.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  text-align: left;
}

.toast svg {
  min-height: 5rem;
  min-height: 5rem;
  max-height: 5.5rem;
  max-width: 5.5rem;
}

.toast h3 {
  font-size: 2.5rem;
  margin-block: 0
}

.toast p {
  font-size: 2rem;
  margin-block: 0;
  font-family: Satoshi;
  font-weight: 500;
  line-height: 3rem;
  letter-spacing: 0;
  text-align: left;
}

div.toast>div.textWrap {
  text-align: left;
}

div.toast .backdrop__ul {
  font-size: 1.5rem
}

div.toast>div.textWrap h1 {
  text-align: left;
  margin: 0 1rem;
  font-weight: 700;
  letter-spacing: 0rem;
  text-align: center;
  font-size: 1.8rem
}

div.toast {
  top: 2.5rem;
  right: 3rem;
}

div.toast.show {
  visibility: visible;
  -webkit-animation: 0.25s staytransparent, 0.5s ease-out 0.25s fadein, 0.5s ease-in 8s fadeout, 1s 8.25s staytransparent;
  animation: 0.25s staytransparent, 0.5s ease-out 0.25s fadein, 0.5s ease-in 8s fadeout, 1s 8.5s staytransparent;
}


@-webkit-keyframes staytransparent {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes staytransparent {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }

  to {
    right: 3rem;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }

  to {
    right: 3rem;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    right: 3rem;
    opacity: 1;
  }

  to {
    right: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    right: 3rem;
    opacity: 1;
  }

  to {
    right: 0;
    opacity: 0;
  }
}

@media only screen and (max-width: 767px) {
  img {
    width: 100%;
  }

  div.toast .backdrop__ul {
    font-size: 0.8rem
  }

  div.toast>div.textWrap h1 {
    font-size: 1rem
  }

  div.toast {
    top: 1rem;
    right: 1rem;
    left: 1rem;
  }

  div.toast.show {
    max-width: 17rem;
    visibility: visible;
    -webkit-animation: 0.25s staytransparent, 0.5s ease-out 0.25s fadein, 0.5s ease-in 5s fadeout, 1s 5.25s staytransparent;
    animation: 0.25s staytransparent, 0.5s ease-out 0.25s fadein, 0.5s ease-in 5s fadeout, 1s 5.5s staytransparent;
  }


  @-webkit-keyframes staytransparent {
    from {
      opacity: 0;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes staytransparent {
    from {
      opacity: 0;
    }

    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadein {
    from {
      right: 0;
      opacity: 0;
    }

    to {
      right: 1rem;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      right: 0;
      opacity: 0;
    }

    to {
      right: 1rem;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      right: 1rem;
      opacity: 1;
    }

    to {
      right: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      right: 1rem;
      opacity: 1;
    }

    to {
      right: 0;
      opacity: 0;
    }
  }

}
.logo-container__App-logo {
  width: 3rem;
  margin: 1rem 0.5rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .logo-container__App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
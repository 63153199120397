section.buttons-container {
  margin-block-start: 1rem;
  display: flex;
  gap: 2rem;
  text-align: center;
  min-height: 2.5rem;
  justify-content: space-between;
}

section.buttons-container :is(button, a):hover {
  background-color: #5093E2;
  color: white;
}

section.buttons-container :is(button, a) {
  min-width: 9rem;
  min-height: 2.5rem;
  font-size: 1rem;
  border-radius: 0.75rem;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
}

a {
  text-decoration: none;
}

section.buttons-container button.linked-in {
  background-color: #5093E2;
  color: white;
}

@media only screen and (max-width: 767px) {
  section.buttons-container {
    gap: 1rem;
  }

  section.buttons-container button {
    min-width: 8rem;
  }

  section.buttons-container {
    justify-content: space-around;
  }
}
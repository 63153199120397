.footer {
  text-align: left;
  display: flex;
  flex: 1;
  background-color: #21222A;
  color: white;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.footer :is(p, span) {
  font-size: 0.8rem;
  color: #61DAFB;
}

.footer img.logo-container__App-logo {
  width: 1.5rem;
}

@media only screen and (max-width: 767px) {
  .footer img.logo-container__App-logo {
    margin: 0.5rem;
  }

  .footer {
    justify-content: center;
  }
}
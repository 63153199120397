.navbar {
  text-align: left;
  display: flex;
  background-color: #21222A;
  color: white;
  align-items: center;
}

.navbar__inner-text {
  display: flex;
  align-items: baseline;
}

.navbar li {
  display: inline;
  padding-inline: 1rem;
  font-weight: 600;
}

.navbar h1 {
  color: #61DAFB;
  margin: 0;
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .navbar h1 {
    color: #61DAFB;
    margin: 0;
    width: min-content;
    font-size: 1rem;
  }

  .navbar {
    justify-content: center;
  }
}
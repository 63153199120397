input {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
}

.checkout-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.checkout-form {
  display: flex;
  gap: 1rem;
}

.checkout-form label {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.checkout-form button {
  width: fit-content
}

.checkout-container__div {
  display: flex;
  gap: inherit;
}

form select {
  font-size: 16px;
  font-weight: bold;
  color: #444;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 2rem;
}

form button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 1rem 2rem;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0.5rem
}

button:hover {
  background-color: #5093E2;
  color: white;
}

input[type="number"] {
  width: 4rem;
  height: 2rem;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 0 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
}

input:focus {
  border-color: #4CAF50;
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-moz-inner-spin-button {
  -moz-appearance: textfield;
}

section.send-eth {
  text-align: center;
  border: 2px grey solid;
  padding: 2rem;
  ;
}

section.send-eth h2 {
  margin-block-start: 0;
}

fieldset {
  padding: 2rem;
}

fieldset h2 {
  margin-block-start: 0;
}

form section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

fieldset.delivery-details {
  flex: 1;
}

div.green {
  color: #4CAF50;
}

div.red {
  color: red;
}

div.center-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.metamask-connect {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .checkout-form {
    flex-direction: column;
    margin-block-end: 2rem;
    max-width: 96vw;
  }

  .checkout-container {
    flex-direction: column;
    background-color: black;
    max-width: 96vw;
  }

  .checkout-container>h1 {
    text-align: center;
    margin-block-end: 0;
  }

  section.send-eth {
    max-width: 96vw;
  }

  .checkout-container__div {
    flex-direction: column;
  }
}
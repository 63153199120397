.eth-payments-form {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.inputs-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

button.pay-now-btn {
  margin-block-end:1rem
}


div.inputs-container :is(input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active) {
   transition: background-color 5000s ease-in-out 0s;
     -webkit-text-fill-color: white !important;
}

* {
  font-family: 'Inter', sans-serif;
  /* outline: 1px solid red; */
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}

/* custom scrollbar */

/* ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: white;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  background: white;
  border-radius: 10px;
} */


@media only screen and (max-width: 767px) {
  /* ::-webkit-scrollbar-thumb {
    background-clip: unset;
  } */
}
.logo-in-content-container__content-logo {
  width: 25rem;
  /* left: 0; */
  pointer-events: none;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  filter: grayscale(1);
}

@media (prefers-reduced-motion: no-preference) {
  .logo-in-content-container__content-logo {
    animation: App-logo-spin infinite 80s linear;
  }
}

@keyframes App-logo-spin {
  from {
    opacity: 0;
    transform: rotate(0deg);
  }

  to {
    opacity: 1;
    transform: rotate(360deg);
  }
}
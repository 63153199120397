main.content {
  text-align: left;
  padding: 1rem;
  padding-block-start: 2rem;
  padding-block-end: 6rem;
  color: white;
  margin: auto;
}

main.content section.cards {
  display: flex;
  overflow: auto;
}

section.shows {
  margin-block-start: 25rem;
}

.cards-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media only screen and (max-width: 767px) {
  .cards-container {
    gap: 0
  }

  main.content {
    padding-block-start: 1rem;
    margin: initial;
  }

  main.content section.cards {
    padding: 1rem;
    gap: 1rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}
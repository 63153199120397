div.backdrop {
  width: 100%;
  background-color: black;
  position: fixed;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
}

.backdrop>button {
  margin-block: 1rem;
}

.backdrop>button,
.backdrop-container>button {
  width: 5rem;
  min-width: 9rem;
  min-height: 2.5rem;
  font-size: 1rem;
  border-radius: 0.75rem;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  div.backdrop {
    /* position: absolute; */
    padding: 0.5rem;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  section.backdrop-container.open {
    position: fixed;
    top: 0;
    z-index: 3;
    height: 100vh;
    width: 100vw;
  }
}